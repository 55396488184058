var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"py-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-1",attrs:{"name":"title","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Título","invalid-message":_vm.form.errors.title || errors[0]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('div',{staticClass:"lg:col-span-1"},[_c('RichTextEditor',{model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1)],1),_c('div',[_c('div',{staticClass:"w-full lg:w-2/3"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),_c('div',[(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)])])]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }